export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'https://api.verificacion.jalisco.gob.mx',
  recaptcha: {
    siteKey: '6LcmrtUqAAAAAIlX5Dx742d-KIxfPxa5jGjDbuYX',
  },
  urlHaciendaOnlinePayment: 'https://gobiernoenlinea1.jalisco.gob.mx/serviciosVehiculares/pagoVerificacionVOE',
  urlMercadopagoPayment: 'https://merpagoshp.jalisco.gob.mx',
};
