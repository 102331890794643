import { User } from '@admin/interfaces/user';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _storageName = {
    token: 'token',
  };
  private _user: User = null;
  constructor() {}

  /**
   * @description Loads the user and assigns it to the user property
   */
  loadUser(): void {
    this._user = this._getUserFromStorage();
  }

  /**
   * @description Checks if the user is logged in
   * @returns Boolean indicating if the user is logged in
   */
  isUserLoggedIn(): boolean {
    this.loadUser();
    return !!this._user;
  }

  /**
   * @description Assigns the token to the user property and stores it in the local storage
   * @param token JWT token
   */
  login(token: string): void {
    const user = this._decodeJWToken(token);
    this._saveToken(token);
    this.user = user;
  }

  /**
   * @description Deletes the user and the token from the local storage and resets the user property
   */
  logout(): void {
    this._user = null;
    this._deleteToken();
  }

  /**
   * @description Returns a boolean indicating if the user is validated
   * @returns Boolean indicating if the user is validated
   */
  isUserValidated(): boolean {
    return this._user.verified;
  }

  /**
   * @description Checks if user role is public
   * @returns Boolean indicating if the user is public
   */
  isUserRolePublic(): boolean {
    return this._user.role.key === 'public';
  }

  /**
   * @description Checks if user role is verifier
   * @returns Boolean indicating if the user is verifier
   */
  isUserRoleVerifier(): boolean {
    return this._user.role.key === 'verifier';
  }

  /**
   * @description Checks if the user is stored in the local storage
   * @returns The user from the local storage or null if it doesn't exist
   */
  private _getUserFromStorage(): User {
    const token = localStorage.getItem(this._storageName.token);
    return token ? this._decodeJWToken(token) : null;
  }

  /**
   * @description Stores the token in the local storage
   * @param token JWT token
   */
  private _saveToken(token: string): void {
    localStorage.setItem(this._storageName.token, token);
  }

  /**
   * @description Deletes the token from the local storage
   */
  private _deleteToken(): void {
    localStorage.removeItem(this._storageName.token);
  }

  /**
   * @description Decodes a JWT token and returns the payload.
   * @param token JWT token
   * @returns The payload of the token
   */
  private _decodeJWToken(token: string): User {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return this._mapUserFromPayload(JSON.parse(window.atob(base64)));
  }

  /**
   * @description Returns only the necessary user data from the payload given by the JWT token
   * @param payload Payload of the JWT token
   * @returns The user data
   */
  private _mapUserFromPayload(payload: any): User {
    return {
      id: payload.id,
      email: payload.email,
      name: payload.name,
      surname: payload.last_name,
      role: payload.role || 'user',
      verified: payload.verified,
      center: payload?.center,
    };
  }

  get user() {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
  }

  get token() {
    return localStorage.getItem(this._storageName.token);
  }
}
