import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ReactiveFormsModule } from '@angular/forms';

import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { AuthGuard } from '@admin/guards/auth.guard';
import { VerificatedGuard } from '@admin/guards/verificated.guard';
import { BlockUIModule } from 'ng-block-ui';
import { AuthVerifierGuard } from './main/checker/guards/auth-verifier.guard';
import { TokenInterceptorService } from './main/authentication/interceptors/token-interceptor.service';

import { MarkdownModule } from 'ngx-markdown';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/website/website.module').then((m) => m.WebsiteModule),
  },
  {
    path: 'sesion',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'cita-rapida',
    loadChildren: () =>
      import('./main/website/pages/quick-appointment/quick-appointment.module').then(
        (m) => m.QuickAppointmentModule
      ),
  },
  {
    path: 'confirmacion',
    canLoad: [AuthGuard],
    canActivate: [VerificatedGuard],
    loadChildren: () =>
      import('./main/admin/pages/register-confirmation/register-confirmation.module').then(
        (m) => m.RegisterConfirmationModule
      ),
  },
  {
    path: 'dashboard',
    canLoad: [AuthGuard, VerificatedGuard],
    loadChildren: () =>
      import('./main/admin/pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'verificador',
    canLoad: [AuthVerifierGuard, VerificatedGuard],
    canActivate: [AuthVerifierGuard],
    loadChildren: () => import('./main/checker/checker.module').then((m) => m.CheckerModule),
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
      anchorScrolling: 'enabled'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // Utils modules
    AngularSvgIconModule.forRoot(),

    // App modules
    LayoutModule,
    ReactiveFormsModule,
    RecaptchaV3Module,

    BlockUIModule.forRoot(),
    MarkdownModule.forRoot(),
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
