import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@admin/services/user.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private _userService: UserService, private _router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let routeRedirect;
    if (this._userService.isUserLoggedIn()) {
      const token = this._userService.token;
      request = request.clone({
        setHeaders: { 'Access-Token': token },
      });
      const { role } = this._userService.user;
      switch (role.key) {
        case 'public':
          routeRedirect = 'sesion/ingresar';
          break;
        case 'verifier':
          routeRedirect = 'sesion/ingresar-verificador';
          break;
        default:
          routeRedirect = '/';
          break;
      }
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this._userService.logout();
            this._router.navigateByUrl(routeRedirect);
          }
        }
        return throwError(err);
      })
    );
  }
}
