import { UserService } from '@admin/services/user.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userService.isUserLoggedIn()) {
      if (this.userService.isUserRolePublic) this.router.navigateByUrl('/dashboard');
      if (this.userService.isUserRoleVerifier) this.router.navigateByUrl('/verificador');
      return false;
    }
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userService.isUserLoggedIn() || !this.userService.isUserRolePublic()) {
      this.router.navigateByUrl('/sesion/ingresar');
      return false;
    }
    return true;
  }
}
